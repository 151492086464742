<!-- 注册账号 -->
<template>
  <div>
    <div class="bgcolor">
      <div class="box">
        <div class="title">
          {{ this.$route.query.type == 2 ? '忘记密码' : '注册账号' }}
        </div>
        <div class="login-form-box">
          <el-form
            ref="loginForm"
            :model="loginForm"
            :rules="loginRules"
            class="login-form"
            auto-complete="on"
            label-position="left"
          >
            <el-form-item
              prop="username"
              class="username"
              v-if="this.$route.query.type == 1"
            >
              <el-input
                class="phone"
                ref="username"
                v-model="loginForm.username"
                placeholder="请输入用户名"
                name="username"
                type="text"
                tabindex="1"
                clearable
              />
            </el-form-item>
            <el-form-item prop="phone" class="username">
              <el-input
                class="phone"
                ref="username"
                v-model="loginForm.phone"
                placeholder="请输入手机号码"
                name="phone"
                type="text"
                tabindex="1"
                clearable
              />
            </el-form-item>
            <el-form-item prop="code">
              <div class="inputs">
                <el-input
                  v-model="loginForm.code"
                  placeholder="请输入图形验证码"
                  class="borderNone"
                ></el-input>
                <img :src="urls" alt @click="upImg" />
              </div>
            </el-form-item>
            <el-form-item prop="verificationCode">
              <div class="inputs">
                <el-input
                  v-model="loginForm.verificationCode"
                  placeholder="请输入验证码"
                  class="borderNone"
                ></el-input>
                <el-button
                  type="primary"
                  size="small"
                  @click="send"
                  :disabled="(disabled = !show)"
                >
                  <div style="width: 70px" v-show="show">获取验证码</div>
                  <div style="width: 70px" v-show="!show" class="count">
                    {{ count }} s
                  </div>
                </el-button>
              </div>
            </el-form-item>
            <el-form-item prop="password" class="username">
              <el-input
                class="phone"
                ref="password"
                v-model="loginForm.password"
                placeholder="请设置登录密码"
                name="password"
                type="password"
                tabindex="1"
                clearable
              />
            </el-form-item>
            <el-form-item prop="confirmPassword" class="username">
              <el-input
                class="phone"
                ref="confirmPassword"
                v-model="loginForm.confirmPassword"
                placeholder="请再次设置登录密码"
                name="confirmPassword"
                type="password"
                tabindex="1"
                clearable
              />
            </el-form-item>
            <el-button
              :loading="loading"
              class="login-btn"
              @click.native.prevent="handleLogin"
              >确认</el-button
            >
          </el-form>
        </div>
      </div>
    </div>
    <!-- 底部     -->
    <elFooter></elFooter>
  </div>
</template>

<script>
import elFooter from '@/components/footer'
import { getImageCode, reportSms, forgetPassword } from '@/api/login'
import { getUser } from '@/api/user'

export default {
  components: { elFooter },
  data() {
    const validateUsername = (rule, value, callback) => {
      const reg = /^1[3456789]\d{9}$/
      if (value === '' || !reg.test(value)) {
        return callback(new Error('请输入正确格式的手机号码 '))
      } else {
        callback()
      }
    }
    const validateCode = (rule, value, callback) => {
      if (value.length !== 6) {
        callback(new Error('请输入六位验证码'))
      } else {
        callback()
      }
    }
    const validatePassword = (rule, value, callback) => {
      if (value.length !== 6) {
        callback(new Error('请输入最少六位登录密码'))
      } else {
        callback()
      }
    }
    return {
      loginForm: {
        phone: '',
        password: '',
        verificationCode: '',
        type: 2,
        username: '',
        code: '',
        confirmPassword: '',
        uuid: '',
      },
      loginRules: {
        phone: [
          {
            required: true,
            trigger: 'blur',
            validator: validateUsername,
          },
        ],
        verificationCode: [
          {
            required: true,
            trigger: 'blur',
            validator: validateCode,
          },
        ],
        code: [
          {
            required: true,
            trigger: 'blur',
            message: '请填写图形验证码',
          },
        ],
      },
      loading: false,
      timer: null,
      isShowCode: false,
      urls: '',
      show: true,
      count: '',
    }
  },
  created() {
    if (this.$route.query.type == 1) {
      document.title = '注册账号'
    } else {
      document.title = '忘记密码'
    }
    this.upImg()
  },
  methods: {
    upImg() {
      getImageCode().then((res) => {
        this.urls = res.data.img
        this.loginForm.uuid = res.data.uuid
      })
    },
    send() {
      const time_count = 60
      if (this.$route.query.type == 1){
        this.loginForm.type = 2
      }else{
        this.loginForm.type = 3
      }
      reportSms(this.loginForm)
        .then((res) => {
          if (res.code == 200) {
            this.$message.success('发送成功')
            this.countDown(time_count)
          }
        })
        .catch((err) => {
          this.upImg()
        })
    },
    countDown(times) {
      if (!this.timer) {
        this.count = times
        this.show = false
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= times) {
            this.count--
          } else {
            this.show = true
            clearInterval(this.timer) // 清除定时器
            this.timer = null
          }
        }, 1000)
      }
    },
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          if (this.loginForm.password !== this.loginForm.confirmPassword) {
            this.$message({
              message: '两次密码不相同',
              type: 'warring',
            })
            return
          }
          this.loading = true
          if (this.$route.query.type == 2) {
            forgetPassword(this.loginForm)
              .then((res) => {
                if (res.code == 200) {
                  this.$message({
                    message: '修改密码成功',
                    type: 'success',
                  })
                  setTimeout(() => {
                    this.$router.go(-1)
                  }, 500)
                }
              })
              .catch(() => {
                this.loading = false
              })
          } else {
            this.loginForm.type = 1
            this.$store
              .dispatch('user/login', this.loginForm)
              .then(() => {
                this.loading = false
                this.$message({
                  message: '注册成功',
                  type: 'success',
                })
                getUser().then((res) => {
                  sessionStorage.setItem('userInfo', JSON.stringify(res.data))
                  this.$store.commit('user/SET_AVATAR', res.data.headImage)
                })
                setTimeout(() => {
                  this.$router.push('/userCenter')
                }, 500)
              })
              .catch(() => {
                this.loading = false
              })
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .el-form-item__error {
  left: 100px;
}
.bgcolor {
  background: hsl(210, 11%, 14%);
  width: 100%;
  min-height: calc(100vh - 210px);
  .box {
    width: 30%;
    background: #fff;
    text-align: center;
    position: fixed;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    .title {
      background-color: #3c60bc;
      font-size: 20px;
      line-height: 50px;
    }
    .login-form-box {
      margin-top: 40px;
      justify-content: center;
      .phone {
        width: 60%;
      }
      .inputs {
        display: flex;
        align-items: center;
        .borderNone {
          width: 35%;
          margin-left: 20%;
        }
        .el-button {
          margin-left: 20px;
        }
        .el-button--primary {
          background-color: #3c60bc;
          border-color: #3c60bc;
        }

        img {
          margin-left: 20px;
        }
      }
      .login-btn {
        width: 60%;
        background: #3c60bc;
        color: #fff;
        margin-bottom: 20px;
      }
    }
  }
}
</style>
